import React from 'react';


export default function ProjectsPage() {

    return (
        <div className='projects-page h-screen snap-center bg-blue-900 text-blue-50'>
            <div className='flex flex-col py-16'>
                <h2 className="py-4 sm:py-10 text-center">
                    Projects 
                </h2>
            </div>
        </div>
    )
}