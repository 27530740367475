import './components/ApplicationWrapper.js'
import ApplicationWrapper from './components/ApplicationWrapper.js';

function App() {
  return (
    <ApplicationWrapper />
  );
}

export default App;
